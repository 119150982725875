<!-- @format -->

<template>
  <div id="app">
    <router-view />
    <press-modal />
    <gewinnspiel-modal />
  </div>
</template>

<script>
  import PressModal from '@/components/Base/Elements/Modals/Press.vue'
  import GewinnspielModal from '@/components/Base/Elements/Modals/Gewinnspiel.vue'

  export default {
    components: {
      PressModal,
      GewinnspielModal,
    },
    computed: {
      local() {
        return process.env.NODE_ENV
      },
    },
    mounted() {
      this.$nextTick(function () {
        this.$store.dispatch('Window/docElem', {
          tmp: window.document.documentElement,
        })
        window.addEventListener('resize', this.sendDispatch)
        this.$store.dispatch('Window/resize')
        this.isMobile()
      })
    },
    methods: {
      sendDispatch(e) {
        this.$store.dispatch('Window/resize')
        this.isMobile()
      },
      isMobile() {
        if (this.$store.getters['Window/isSize'].width < 1023) {
          this.$router.currentRoute.path !== '/artists'
            ? this.$router.currentRoute.path === '/mobile'
              ? ''
              : this.$router.push('/mobile')
            : this.$router.currentRoute.path === '/artists'
            ? ''
            : this.$router.push('/artists')
        } else {
          this.$router.currentRoute.path === '/' ? '' : this.$router.push('/')
        }
      },
    },
    head: {
      meta: function () {
        return [
          { charset: 'utf-8' },
          { name: 'viewport', content: 'width=device-width,initial-scale=1' },
          {
            property: 'og:title',
            content: this.$config.title,
            template: (chunk) => `${chunk} - Offizielle Webseite`,
            hid: 'og:title',
          },
          {
            property: 'og:url',
            content: this.$config.url,
            hid: 'og:url',
          },
          {
            property: 'og:type',
            content: 'website',
            hid: 'og:type',
          },
          {
            property: 'og:description',
            content: this.$config.facebook.og_description,
            hid: 'og:description',
          },
          {
            property: 'og:image',
            content: this.$config.url + '/' + this.$config.facebook.og_image,
            hid: 'og:image',
          },
          {
            property: 'keywords',
            content: this.$config.keywords,
          },
          {
            property: 'page-topic',
            content: 'Medien',
          },
          {
            property: 'robots',
            content: 'index, follow',
          },
          {
            property: 'author',
            content: this.$config.verantwortlich,
          },
          {
            property: 'publisher',
            content: this.$config.verantwortlich,
          },
          {
            property: 'copyright',
            content: this.$config.verantwortlich,
          },
          {
            property: 'description',
            content: this.$config.facebook.og_description,
            hid: 'description',
          },
        ]
      },
      link: function () {
        return [
          { rel: 'icon', type: 'image/x-icon', href: this.$config.favicon },
        ]
      },
    },
  }
</script>

<style lang="scss">
  /** @format */

  #app {
    font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>
