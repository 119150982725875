<!-- @format -->

<template>
  <div id="index" style="pointer-events: none; position: relative;">
    <div class="desktop-top">
      <div class="bg">
        <img src="@/assets/images/bg_top.jpg" alt />
      </div>
      <img class="cite" :class="$mq" src="@/assets/images/top_cite.png" alt />
      <img
        class="logo"
        :class="$mq"
        :src="require(`@/assets/images/` + $config.tt)"
        alt
      />
      <a
        class="fb-black"
        href="https://www.facebook.com/FilmweltVerleihagentur/"
        target="_blank"
      >
        <img src="@/assets/images/fb_black.png" alt />
      </a>
      <div class="packshot">
        <a
          class="gtop"
          href="https://www.amazon.de/Cody-Wie-Hund-Welt-ver%C3%A4ndert/dp/B08DSS7WCX/ref=sr_1_1?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=Cody&qid=1602073151&sr=8-1&tag= filmweltverle-21"
          target="_blank"
        ></a>
        <img src="@/assets/images/gewinnspiel.png" alt />
      </div>
      <div id="scene">
        <div class="pitem" data-depth="0.2">
          <img src="@/assets/images/parallax_bg.jpg" alt />
        </div>
        <div class="pitem" data-depth="0.3">
          <img src="@/assets/images/parallax_vg.png" alt />
        </div>
      </div>
      <!-- <div class="parallax" :class="$mq">
        <div class="fx" :class="$mq"></div>
      </div>-->
      <div class="trailer" :class="$mq">
        <div v-if="$config.youtube.display">
          <div
            @click="ytVideo"
            class="open-trailer"
            style="position: relative;"
          >
            <img src="@/assets/images/trailer_poster.png" alt class="poster" />
            <div class="trailer-misc">
              <img
                :style="[
                  ratio < 1.73
                    ? {
                        width: '50px',
                      }
                    : {
                        width: '70px',
                      },
                ]"
                class="play-btn"
                src="@/assets/images/trailer/play.png"
                alt
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { EventBus } from '@/plugins/event-bus.js'
  import Parallax from 'parallax-js'

  export default {
    props: {
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
      ratio: {
        type: Number,
        default: 1.78,
      },
      trailer: {
        type: Boolean,
        default: false,
      },
    },
    mounted() {
      var scene = document.getElementById('scene')
      var parallaxInstance = new Parallax(scene)
    },
    methods: {
      ytVideo() {
        EventBus.$emit('open-yt')
      },
      emitGewinnspielClick() {
        EventBus.$emit('gewinnspielmodal')
      },
    },
  }
</script>

<style lang="scss">
  /** @format */

  .desktop-top {
    position: relative;
    padding-top: $header_height;
    padding-bottom: 1rem;
    & > .bg {
      z-index: 0;
      pointer-events: none;
      margin: 0 2rem;
      width: calc(100% - 4rem);
    }

    .logo {
      pointer-events: none;
      position: absolute;
      z-index: 1;
      top: 20%;
      right: 15%;
      width: 25%;
    }

    .cite {
      pointer-events: none;
      position: absolute;
      z-index: 5;
      top: 45%;
      right: 3%;
      width: 18%;
    }

    .fb-black {
      pointer-events: auto;
      position: absolute;
      z-index: 100;
      right: 65px;
      top: 80px;
    }

    .packshot {
      pointer-events: auto;
      position: absolute;
      z-index: 100;
      right: 5%;
      bottom: 15%;
      display: inline-block;
      width: 12%;
      height: 20%;
      img {
        width: 100%;
      }
      .gtop {
        position: absolute;
        width: 100%;
        height: 60%;
      }
      .gbottom {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 40%;
      }
    }

    #scene {
      position: absolute;
      width: calc(56% - 2rem);
      height: calc(100% - 2rem);
      top: 0;
      right: 2rem;
      z-index: 0;
      overflow: hidden;
      .pitem {
        width: 164%;
        height: 110%;
        margin-left: -25%;
        img {
          width: auto;
          height: 100%;
        }
      }
    }

    .trailer {
      pointer-events: auto;
      margin-top: 3%;
      position: absolute;
      top: 12%;
      left: 38%;
      width: 18.5%;
      margin-bottom: 1.5rem;
      border: 8px solid white;
      cursor: pointer;
      .trailer-misc {
        position: absolute;
        top: 0px;
        left: 0;
        z-index: 111;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .trailer-text {
          display: block;
          position: absolute;
          z-index: 2;
          margin-top: 15%;
          color: #fff;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 19px;
          text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
          &.xl {
            font-size: 13px;
          }
        }
      }
    }
  }
</style>
