<!-- @format -->

<template>
  <div id="inhalt" class="inhalt">
    <div class="content" :class="$mq">
      <p>
        Eine junge Familie adoptiert den rumänischen Straßenhund Cody in die
        Schweiz. Cody wächst ihr schnell ans Herz, verändert ihr Leben und
        eröffnet ihr eine neue Welt: das Zusammenleben von Mensch und Tier. Auf
        der Suche nach den Spuren seiner Vergangenheit erfährt die Familie mehr
        über das Leben in Rumänien, das Cody hinter sich gelassen hat. Er war
        dort mit der Streuner-Hündin Blanche in Freiheit, aber auch in ständiger
        Gefahr, umzukommen. Was passiert, wenn er mit seiner Vergangenheit
        konfrontiert wird und seine Gefährtin wiedersieht? Wie wird er sich
        entscheiden – bleibt er in seiner Familie oder bevorzugt er das Leben
        mit Blanche?
      </p>
      <p class="bottom" :class="$mq">
        Codys Schicksal führt zu wichtigen Themen unserer Zeit: Wie gehen
        Menschen mit ihrer Umwelt, insbesondere mit Tieren um – und sind sie
        dazu bereit, ihnen Rechte einzuräumen?
      </p>
    </div>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss">
  /** @format */

  .inhalt {
    border-bottom: 10px solid $primary;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url('~@/assets/images/bg_inhalt.jpg');
    height: 810px;
    overflow: hidden;
    position: relative;

    .content {
      text-align: left;
      position: absolute;
      top: 20px;
      left: 50%;
      margin-left: -200px;
      height: calc(100% - 4rem);
      width: 700px;
      color: #fff;
      &.lg,
      &.xl {
        top: 140px;
        width: 800px;
      }
      &.xxl {
        top: 160px;
        width: 900px;
      }

      p {
        font-size: 17px;
        line-height: 21px;
        margin-bottom: 1rem;
        font-weight: 200;
      }
      .bottom {
        color: $primary !important;
        font-size: 20px;
        font-weight: 700;
        &.sm {
          margin-top: 16px;
        }
      }
    }
  }
</style>
