<!-- @format -->

<template>
  <div v-if="gewinnspielmodal" class="gewinnspiel-modal" :class="$mq">
    <div class="gewinnspiel-modal__wrapper" :class="$mq">
      <div @click="close()" class="gewinnspiel-modal__close">&times;</div>
      <h2>TEILNAHMEBEDINGUNGEN</h2>
      <h3>TEILNAHMESCHLUSS IST DER 31. AUGUST 2020</h3>
      <p>
        Der Rechtsweg ist ausgeschlossen. Das Gewinnspiel wird ausgerichtet von
        der Filmwelt Verleihagentur GmbH. Mitarbeiter und Angehörige der
        beteiligten Firmen sind von der Teilnahme ausgeschlossen, eine
        Barauszahlung oder Übertragung der Preise ist nicht möglich. Teilnehmer
        müssen einen Wohnsitz in der Bundesrepublik Deutschland haben; ein
        Versand oder Auszahlung des Preises in Länder außerhalb Deutschlands ist
        nicht möglich. Die Teilnahme von automatisierten Gewinnspielservices ist
        untersagt. Teilnehmer unter 18 Jahren benötigen das Einverständnis ihrer
        Erziehungsberechtigten. Die Teilnahme gilt als Bestätigung, dass diese
        Zustimmung eingeholt wurde, und als Anerkennung dieser Regeln. Zur
        Teilnahme ist eine Frage richtig zu beantworten. Ist der Preisträger,
        der die Frage korrekt beantwortet hat, noch nicht volljährig, gilt
        ausdrücklich, dass der Preis an den Erziehungsberechtigten oder Vormund
        (bzw. mit schriftlicher Genehmigung des Erziehungsberechtigten oder
        Vormunds an den Preisträger) ausgeliefert wird. Filmwelt übernimmt
        keinerlei Verantwortung für Antworten, die auf dem Übertragungsweg
        verloren gehen bzw. mit Verspätung eingehen. Unter allen Teilnehmern,
        die die Frage richtig beantwortet haben entscheidet das Los. Die
        Gewinner werden schriftlich benachrichtigt. Es wird ausdrücklich darauf
        hingewiesen, dass der Veranstalter nach eigenem Ermessen und ohne
        vorherige Ankündigung den Versand eines Preises stornieren kann, wenn
        die Auslieferung des Preises an die Anschrift des Preisträgers erfolglos
        verläuft und der Veranstalter trotz angemessener Bemühungen nicht die
        notwendigen Informationen zur erfolgreichen Auslieferung des Preises
        einholen kann. Die Daten der Teilnehmer werden nicht an Dritte
        weitergegeben und nach Beendigung des Gewinnspieles gelöscht.
      </p>
    </div>
  </div>
</template>

<script>
  import { EventBus } from '@/plugins/event-bus.js'
  export default {
    data() {
      return {
        gewinnspielmodal: false,
      }
    },
    created() {
      /* eslint-disable-next-line no-undef */
      EventBus.$on(
        'gewinnspielmodal',
        function () {
          this.gewinnspielmodal = true
        }.bind(this)
      )
    },
    methods: {
      close() {
        this.gewinnspielmodal = false
      },
    },
  }
</script>

<style lang="scss">
  /** @format */

  .gewinnspiel-modal {
    $self: &;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 222;
    &.sm,
    &.md {
      display: block;
      position: absolute;
      z-index: 9876;
    }
    &__wrapper {
      position: relative;
      border: 2px solid black;
      width: 70%;
      background-color: rgba(0, 0, 0, 0.6);
      color: white;
      padding: 2rem;
      &.lg {
        width: 90%;
      }
      &.sm,
      &.md {
        padding: 5%;
        width: 90%;
      }
      #{$self}__close {
        position: absolute;
        top: 0;
        right: 0;
        color: #fff;
        margin-right: 10px;
        margin-top: 16px;
        cursor: pointer;
        font-size: 48px;
        line-height: 0;
        font-weight: 700;
        &:hover {
          color: red;
        }
      }
    }
  }
</style>
