<!-- @format -->

<template>
  <footer class="footer" :class="$mq">
    <div class="wrapper" :class="$mq">
      <div class="tt" :class="$mq">
        <img
          class="logo"
          :src="require(`@/assets/images/` + $config.tt_small)"
          alt
        />
      </div>
      <div class="kinostart" :class="$mq">
        <div class="top" :class="$mq">
          Ab 05.11. auf DVD & Video-on-Demand
        </div>
        <div class="bottom" :class="$mq">
          ab 27.10. als digitaler Download
        </div>
      </div>
      <!-- SECTION Footer Company -> Chooses Footer by $config.verantwortlich -> look @ computed -->
      <component
        class="footer__company"
        :is="companyFooter"
        :teilnahmebedingung="teilnahmebedingung"
      ></component>
      <component class="footer__social" :is="companySocial"></component>
      <div class="info" :class="$mq">
        * Der mit Sternchen (*) gekennzeichnete Link ist ein sogenannter
        Affiliate-Link. Wenn Sie auf einen Affiliate-Link klicken und über
        diesen Link einkaufen, erhält Filmwelt Verleihagentur GmbH vom
        betreffenden Online-Shop oder Anbieter eine Provision. Für Sie verändert
        sich der Preis nicht.
      </div>
      <div class="footer__billing" :class="$mq">
        <img src="@/assets/images/billing.png" alt />
      </div>
      <div class="cb-restore"></div>
    </div>
  </footer>
</template>

<script>
  export default {
    props: {
      billing: {
        type: Boolean,
        default: true,
      },
      youtubenote: {
        type: Boolean,
        default: true,
      },
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
      teilnahmebedingung: {
        type: String,
        default: 'leer',
      },
    },
    computed: {
      companyFooter() {
        return () =>
          import(
            `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Footer.vue`
          )
      },
      companySocial() {
        return () =>
          import(
            `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Social.vue`
          )
      },
    },
  }
</script>

<style lang="scss">
  /** @format */

  .footer {
    $self: &;

    background-color: $primary;
    border: 2rem solid #fff;
    text-align: center;
    &.md,
    &.sm {
      border: none;
    }
    .wrapper {
      padding: 3rem 0 3rem;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 1024px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: stretch;
      .tt {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        &.sm {
          display: flex;
          align-items: flex-start;
          justify-content: space-around;
          img {
            flex: 0 1 auto;
            width: initial;
            max-width: 95%;
            &.logo {
              width: 70vw;
            }
            &.dog {
              width: 28vw;
            }
          }
        }
      }
      &.sm,
      &.md {
        width: 100%;
        padding: 30px 30px;
      }
    }

    .kinostart {
      position: relative;
      text-align: center;
      text-transform: uppercase;
      color: white;
      font-weight: 700;
      padding: 10px 0;
      &.sm,
      &.md {
        padding-top: 10px;
        background-color: $primary;
      }
      .top {
        font-size: clamp(2rem, 2.6rem, 3rem);
        &.lg,
        &.xl,
        &.xxl {
          line-height: 1.2;
          $min_width: 1024px;
          $max_width: 2560px;
          $min_font: 22px;
          $max_font: 36px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
        }
        &.sm,
        &.md {
          margin: 0 auto;
          width: 50%;
          margin-bottom: 10px;
          $min_width: 300px;
          $max_width: 1023px;
          $min_font: 15px;
          $max_font: 45px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
        }
      }
      .bottom {
        font-weight: 700;
        &.lg,
        &.xl {
          $min_width: 1024px;
          $max_width: 2560px;
          $min_font: 15px;
          $max_font: 40px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
        }
        &.xxl {
          $min_width: 1024px;
          $max_width: 2560px;
          $min_font: 10px;
          $max_font: 33px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
        }
        &.sm,
        &.md {
          margin: 0 auto;
          width: 58%;
          $min_width: 300px;
          $max_width: 1023px;
          $min_font: 15px;
          $max_font: 45px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
        }
      }
    }

    #{$self}__billing {
      margin-top: 20px;
      &.sm {
        margin-top: 15px;
        img {
          width: 90%;
        }
      }
      &.md {
        margin-top: 15px;
        img {
          width: 80%;
        }
      }
      &.sm,
      &.md,
      &.lg,
      &.xl {
        padding: 0 5%;
      }
    }
    .info {
      width: 80%;
      margin: 0 auto;
      font-size: 12px;
      margin-top: 5px;
      &.sm {
        font-size: 10px;
        width: 60%;
      }
    }
  }
</style>
