<!-- @format -->

<template>
  <mq-layout mq="lg+">
    <header id="header" class="header" :class="$mq">
      <div class="header__content">
        <nav id="navi">
          <BaseNavigation />
        </nav>
        <nav v-if="kinofinder" id="finder">
          <BaseKinofinder v-if="$config.kinoservice.display" />
        </nav>
      </div>
      <div class="base-kinostart" :class="$mq">
        <!-- eslint-disable-next-line -->
        <div class="text" :class="$mq" v-html="kinostart"></div>
      </div>
    </header>
  </mq-layout>
</template>

<script>
  export default {
    props: {
      social: {
        type: Boolean,
        default: true,
      },
      kinofinder: {
        type: Boolean,
        default: true,
      },
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
    },
    computed: {
      companySocial() {
        return () =>
          import(
            `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Social.vue`
          )
      },
    },
  }
</script>

<style lang="scss">
  /** @format */

  .header {
    $self: &;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $header-height;
    background-color: $primary;
    z-index: 9999;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &__content {
      position: relative;
      display: flex;
      left: 2rem;
      justify-content: center;
      flex-direction: row;
      height: $header-height;
    }
    .base-kinostart {
      position: relative;
      right: 2rem;
      text-transform: uppercase;
      height: $header-height;
      line-height: $header-height;
      color: $header-link;
      font-weight: 700 !important;
      .text {
        &.lg,
        &.xl,
        &.xxl {
          $min_width: 1024px;
          $max_width: 2560px;
          $min_font: 16px;
          $max_font: 36px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
        }
        &.sm,
        &.md {
          $min_width: 300px;
          $max_width: 1023px;
          $min_font: 22px;
          $max_font: 45px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
        }
      }
    }
  }
</style>
