var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"pointer-events":"none","position":"relative"},attrs:{"id":"index"}},[_c('div',{staticClass:"desktop-top"},[_vm._m(0),_c('img',{staticClass:"cite",class:_vm.$mq,attrs:{"src":require("@/assets/images/top_cite.png"),"alt":""}}),_c('img',{staticClass:"logo",class:_vm.$mq,attrs:{"src":require("@/assets/images/" + _vm.$config.tt),"alt":""}}),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"trailer",class:_vm.$mq},[(_vm.$config.youtube.display)?_c('div',[_c('div',{staticClass:"open-trailer",staticStyle:{"position":"relative"},on:{"click":_vm.ytVideo}},[_c('img',{staticClass:"poster",attrs:{"src":require("@/assets/images/trailer_poster.png"),"alt":""}}),_c('div',{staticClass:"trailer-misc"},[_c('img',{staticClass:"play-btn",style:([
                _vm.ratio < 1.73
                  ? {
                      width: '50px',
                    }
                  : {
                      width: '70px',
                    } ]),attrs:{"src":require("@/assets/images/trailer/play.png"),"alt":""}})])])]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg"},[_c('img',{attrs:{"src":require("@/assets/images/bg_top.jpg"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"fb-black",attrs:{"href":"https://www.facebook.com/FilmweltVerleihagentur/","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/fb_black.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"packshot"},[_c('a',{staticClass:"gtop",attrs:{"href":"https://www.amazon.de/Cody-Wie-Hund-Welt-ver%C3%A4ndert/dp/B08DSS7WCX/ref=sr_1_1?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=Cody&qid=1602073151&sr=8-1&tag= filmweltverle-21","target":"_blank"}}),_c('img',{attrs:{"src":require("@/assets/images/gewinnspiel.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"scene"}},[_c('div',{staticClass:"pitem",attrs:{"data-depth":"0.2"}},[_c('img',{attrs:{"src":require("@/assets/images/parallax_bg.jpg"),"alt":""}})]),_c('div',{staticClass:"pitem",attrs:{"data-depth":"0.3"}},[_c('img',{attrs:{"src":require("@/assets/images/parallax_vg.png"),"alt":""}})])])}]

export { render, staticRenderFns }