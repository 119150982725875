<!-- @format -->

<template>
  <div id="protagonisten" class="protagonisten" :class="$mq">
    <div class="content">
      <h2 :class="$mq">Die Protagonisten</h2>
      <div class="btn_row">
        <div
          @mouseover="p1 = true"
          @mouseleave="p1 = false"
          class="btn"
          data-rel="0"
        >
          <img src="@/assets/images/protagonisten/prot_01.jpg" alt />
        </div>
        <div
          @mouseover=";(p2 = true), (p1 = false)"
          @mouseleave="p2 = false"
          class="btn"
          data-rel="1"
        >
          <img src="@/assets/images/protagonisten/prot_02.jpg" alt />
        </div>
        <div
          @mouseover=";(p3 = true), (p1 = false)"
          @mouseleave="p3 = false"
          class="btn"
          data-rel="2"
        >
          <img src="@/assets/images/protagonisten/prot_03.jpg" alt />
        </div>
      </div>
      <div class="text">
        <img
          class="mob-pic"
          :class="$mq"
          src="@/assets/images/mob_prot_01.jpg"
          alt
        />
        <div v-show="p1" class data-rel="0">
          <p class="name">Maike Maja Nowak</p>
          <p>
            wurde 1961 in Ostdeutschland geboren, lebte viele Jahre in einem
            einsamen Dorf in der Russischen Weite mit einem Rudel wilder Hunde.
            Sie hat mehrere erfolgreiche Bücher über ihre Arbeit veröffentlicht.
            Mit Titeln wie „Wie viel Mensch braucht ein Hund“ oder „Abenteuer
            Vertrauen. Vollkommen, aber nicht perfekt. Was Menschen von Hunden
            lernen können.“ landete sie mehrfach in der Spiegel
            Bestseller-Liste. Sie ist eine international bekannte Wegbereiterin
            für Mensch wie Hund.
          </p>
        </div>
        <img
          class="mob-pic"
          :class="$mq"
          src="@/assets/images/mob_prot_02.jpg"
          alt
        />
        <div v-show="p2" class data-rel="1">
          <p class="name">Cristina Paun</p>
          <p>
            hat in Targoviste, Rumänien ca. 6-8‘000 Hundeleben gerettet, seit
            das Tötungsgesetz 2014 in Kraft trat. Sie war diejenige, die Cody
            und Blanche von der Straße holte. In der Zwischenzeit führt sie drei
            Hundeheime in Targoviste mit über 1‘500 Hunden, die auf eine
            Adoption warten. Die Dachorganisation „Wonderland“ ist eine
            Institution in Rumänien, die nicht nur Hunden sondern allen Tieren
            in Not hilft. Nebenbei arbeitet Cristina in der
            Telekommunikationsbranche und steckt einen großen Teil ihres Gehalts
            in die Finanzierung der Hundeheime.
          </p>
        </div>
        <img
          class="mob-pic"
          :class="$mq"
          src="@/assets/images/mob_prot_03.jpg"
          alt
        />
        <div v-show="p3" class data-rel="2">
          <p class="name">Mark Rowlands,</p>
          <p>
            geboren 1962 in Newport, Wales, ist ein britischer Schriftsteller
            und Professor für Philosophie, der an der Universität Miami lehrt.
            Ein zentrales Element von Rowlands Philosophie ist seine
            Verteidigung einer Tierrechtsphilosophie und einer
            Speziesismuskritischen Position. Mit „Animal Rights. A Philosophical
            Defence“ bzw. „Animal Rights. Moral Theory and Practice“ oder „The
            Philosopher and the Wolf. Lessons from the wild on Love, Death and
            Happiness“ sind ihm viel beachtete Werke der Tierrechtsphilosophie
            gelungen.
          </p>
        </div>
        <img
          class="mob-pic"
          :class="$mq"
          src="@/assets/images/mob_prot_04.jpg"
          alt
        />
        <div v-show="p4" class data-rel="3">
          <p class="name">Martin &amp; Selina Skalsky</p>
          <p>
            haben Cody 2014 adoptiert. Das verheiratete Paar hatte mit Cody
            viele Herausforderungen zu meistern und erlebte im Umgang mit ihm
            viele Veränderungen – auch mit Auswirkungen auf ihr eigenes Leben.
            In der Zwischenzeit ist mit der kleinen Mila ihre erste Tochter ins
            Rudel gekommen.
          </p>
        </div>
        <img
          class="mob-pic"
          :class="$mq"
          src="@/assets/images/mob_prot_05.jpg"
          alt
        />
        <div v-show="p5" class data-rel="4">
          <p class="name">Marsha Hickmott</p>
          <p>
            gibt Hunden aus Rumänien in ihrem Haus in London ein temporäres
            Zuhause, bevor sie weitervermittelt werden. Sie lebt derzeit mit
            drei Hunden, eine davon ist Blanche, die langjährige Weggefährtin
            von Cody auf den Strassen von Targoviste. Darüber hinaus engagiert
            sich Marsha als Fundraiserin für mehrere Tierschutzorganisationen
            mit speziellem Fokus auf rumänische Straßenhunde.
          </p>
        </div>
        <img
          class="mob-pic"
          :class="$mq"
          src="@/assets/images/mob_prot_06.jpg"
          alt
        />
        <div v-show="p6" class data-rel="5">
          <p class="name">Lya Battle</p>
          <p>
            gründete das „Land der Streuner“ – Territorio de Zaguates. In diesem
            außerordentlichen Hunde-Shelter in den Bergen oberhalb Heredia in
            Costa Rica bietet sich über 1300 Hunden eine Heimat. Sie können dort
            auf einem riesigen Grundstück – über 530 Fußballfelder groß – frei
            leben.
          </p>
        </div>
      </div>
      <div class="btn_row">
        <div
          @mouseover=";(p4 = true), (p1 = false)"
          @mouseleave="p4 = false"
          class="btn"
          data-rel="3"
        >
          <img src="@/assets/images/protagonisten/prot_04.jpg" alt />
        </div>
        <div
          @mouseover=";(p5 = true), (p1 = false)"
          @mouseleave="p5 = false"
          class="btn"
          data-rel="4"
        >
          <img src="@/assets/images/protagonisten/prot_05.jpg" alt />
        </div>
        <div
          @mouseover=";(p6 = true), (p1 = false)"
          @mouseleave="p6 = false"
          class="btn"
          data-rel="5"
        >
          <img src="@/assets/images/protagonisten/prot_06.jpg" alt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        p1: true,
        p2: false,
        p3: false,
        p4: false,
        p5: false,
        p6: false,
      }
    },
  }
</script>

<style lang="scss">
  /** @format */

  .protagonisten {
    background-color: #000;
    overflow: hidden;
    color: $primary;
    &.sm,
    &.md {
      padding-bottom: 0px;
    }
    &.lg,
    &.xl,
    &.xxl {
      padding-bottom: 2rem;
    }

    .content {
      margin: 0 auto;
      width: 1024px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
    .mob-pic {
      &.lg,
      &.xl,
      &.xxl {
        display: none;
      }
    }
    h2 {
      font-size: 2.4rem;
      margin-bottom: 2rem;
      color: $primary;
      text-align: center;
    }

    .btn_row {
      display: flex;
      justify-content: space-between;

      .btn {
        position: relative;
        flex: 1 1 auto;
        max-width: 30%;
        cursor: pointer;
        opacity: 0.5;

        img {
          background-color: #fff;
          padding: 0.5rem;
          border-bottom: 0.5rem solid #000;
        }

        &.active,
        &:focus,
        &:hover {
          opacity: 1;

          img {
            border-color: $primary;
          }
        }
      }

      &:last-of-type .btn {
        img {
          border-top: 0.5rem solid #000;
          border-bottom: none;
        }

        &.active,
        &:focus,
        &:hover {
          img {
            border-color: $primary;
          }
        }
      }
    }

    .text {
      position: relative;
      min-height: 14.5rem;
      color: $primary;
      .name {
        color: $primary;
      }
      div {
        padding: 2rem 0;
        color: #fff;
        text-align: left;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        p:first-of-type {
          font-size: 1.3rem;
          margin-bottom: 1rem;
        }

        p {
          line-height: 1.5rem;
          margin-bottom: 0;
        }
      }
    }
  }
</style>
