<!-- @format -->

<template>
  <div :id="id" class="nova-card" @click="doIt">
    <div class="nova-card__content" :style="cardContentStyle">
      <div class="nova-card__header">
        <div class="nova-card__bg" :style="imageStyle" />
        <div class="nova-card__teaser-wrapper">
          <h1 v-if="$slots.headline" :style="headlineStyle">
            <slot name="headline" />
          </h1>
          <div v-if="$slots.teaser" class="nova-card__teaser">
            <slot name="teaser" />
          </div>
          <div class="nova-card__btn-wrapper" :style="buttonWrapperStyle">
            <div class="nova-card__btn-open" :style="buttonTopStyle">
              <PlusIcon />
              {{ openButton }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PlusIcon from 'vue-material-design-icons/PlusCircle.vue'

  export default {
    components: {
      PlusIcon,
    },
    props: {
      link: {
        type: String,
        default: '',
      },
      close: {
        type: String,
        default: 'X',
      },
      'close-color': {
        type: String,
        default: 'white',
      },
      'close-button': {
        type: String,
        default: 'Zurück zur Seite',
      },
      'open-button': {
        type: String,
        default: 'weiterlesen',
      },
      image: {
        type: String,
        default: 'https://via.placeholder.com/500x400.png',
      },
      'image-height': {
        type: String,
        default: '300',
      },
      'image-align': {
        type: String,
        default: 'top',
      },
      'headline-fontsize': {
        type: String,
        default: '32px',
      },
      'headline-lineheight': {
        type: String,
        default: '1',
      },
      'headline-marginbottom': {
        type: String,
        default: '15px',
      },
      'teaser-padding': {
        type: String,
        default: '5%',
      },
      'btn-top-bg': {
        type: String,
        default: 'black',
      },
      'btn-top-color': {
        type: String,
        default: 'white',
      },
      'btn-top-align': {
        type: String,
        default: 'right',
      },
      'btn-bottom-bg': {
        type: String,
        default: 'black',
      },
      'btn-bottom-color': {
        type: String,
        default: 'white',
      },
      'btn-bottom-align': {
        type: String,
        default: 'right',
      },
      'card-bg': {
        type: String,
        default: 'white',
      },
      'card-margin': {
        type: String,
        default: '5%',
      },
      'card-border-radius': {
        type: String,
        default: '20px',
      },
      'card-content-padding': {
        type: String,
        default: '5%',
      },
    },
    data() {
      return {
        id: 'nova-card__' + Math.ceil(Math.random() * 12345),
        top: 0,
        height: 0,
      }
    },
    computed: {
      cardImageHeight() {
        return this.$store.getters['Window/isSize'].width < 700
          ? this.imageHeight
          : this.imageHeight
      },
      cardContentStyle() {
        return `backgroundColor: ${this.cardBg};
                    margin: 0 ${this.cardMargin};
                    borderRadius: ${this.cardBorderRadius};
                    --padding: ${this.cardContentPadding};`
      },
      imageStyle() {
        return (
          `height: ${this.cardImageHeight}px;
                    background: transparent url(` +
          require('@/assets/images/cards/' + this.image) +
          `) no-repeat;
                    borderTopLeftRadius: ${this.cardBorderRadius};
                    borderTopRightRadius: ${this.cardBorderRadius};
                    backgroundPositionX: center;
                    backgroundPositionY: ${this.imageAlign};`
        )
      },
      headlineStyle() {
        return `fontSize: ${this.headlineFontsize};
                    lineHeight: ${this.headlineLineheight};
                    marginBottom: ${this.headlineMarginbottom};`
      },
      buttonWrapperStyle() {
        return `textAlign: ${this.btnTopAlign};`
      },
      buttonTopStyle() {
        return `backgroundColor: ${this.btnTopBg};
                    color: ${this.btnTopColor};`
      },
    },
    methods: {
      doIt() {
        if (this.link !== '') {
          this.$router.push(this.link)
        }
      },
    },
  }
</script>

<style lang="scss">
  /** @format */

  .nova-card {
    z-index: 10;
    font-size: 19px;
    line-height: 1.5;
    --padding: 5%;
    margin: 30px auto 0 auto;
    $self: &;
    position: relative;
    transition-property: padding, height, transform, border-radius,
      -webkit-transform;
    transition-duration: 1s;
    background-color: transparent;
    overflow-y: scroll;
    max-width: 850px;
    span {
      font-weight: 900;
    }
    &__p {
      margin: 0 0 15px 0;
    }
    &__content {
      position: relative;
      z-index: 280;
      transition-property: margin, -webkit-transform;
      transition-duration: 1s;
      #{$self}__header {
        position: relative;
        color: white;
        #{$self}__bg {
          position: relative;
          background-size: cover;
          width: 100%;
        }
        #{$self}__teaser-wrapper {
          padding: var(--padding) var(--padding) 0 var(--padding);
          // background-color: $primary;
          h1 {
            margin: 0;
            font-size: 33px;
            font-weight: 900;
            text-transform: uppercase;
          }
          #{$self}__teaser {
            margin-bottom: 15px;
            font-size: 19px;
            color: black !important;
          }
          #{$self}__btn-wrapper {
            width: 100%;
            #{$self}__btn-open {
              text-transform: uppercase;
              font-weight: 700;
              cursor: pointer;
              // padding: 5px 15px;
              margin-bottom: var(--padding);
              font-size: 22px;
              display: inline-block;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }

  @function strip-unit($value) {
    @return $value / ($value * 0 + 1);
  }

  @mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
      & {
        font-size: $min-font-size;
        @media screen and (min-width: $min-vw) {
          font-size: calc(
            #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
              ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          );
        }
        @media screen and (min-width: $max-vw) {
          font-size: $max-font-size;
        }
      }
    }
  }
</style>
