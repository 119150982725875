<!-- @format -->

<template>
  <div id="fotos" class="fotos">
    <div class="fotos__wrapper">
      <BaseCarousel :autoplay="false" :dots="false" :fullscreen="false">
        <div class="source">
          <div
            v-for="value in this.$config.gallery.count"
            :key="value"
            class="carousel-cell"
          >
            <img
              :src="require(`@/assets/images/gallery/` + value + `.jpg`)"
              alt
              class="pictures"
            />
          </div>
        </div>
      </BaseCarousel>
    </div>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss">
  /** @format */

  .fotos {
    $self: &;
    position: relative;
    background: white;
    height: 100%;
    border-bottom: 10px solid $primary;
    padding-bottom: 2rem;
    padding-top: 1rem;
    &__wrapper {
      width: 100%;
      height: 100%;
    }
  }
</style>
